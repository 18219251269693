import { useCallback, useEffect, useRef, useState } from "react";
import { MdExitToApp } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

import "./profileDropdown.css";

export const ProfileDropdown = () => {
	const componentRef = useRef(null);
	const [activeClass, setActiveClass] = useState(null);

	//Selecting the active class element
	const toggleActiveClass = (element) => {
		if (activeClass === null) {
			setActiveClass(element);
		} else if (activeClass === element) {
			setActiveClass(null);
		} else {
			setActiveClass(element);
		}
	};

	const userUnq = useSelector((state) => state.authSlice?.user);
	console.log(userUnq);

	const removeAllTokens = () => {
		const cookies = new Cookies();
		const keys = ["refresh_token", "expires_at", "access_token"];
		keys.forEach((key) => {
			console.log(key);
			cookies.remove(key, {
				path: "/",
				domain: ".intechadditive.com",
				secure: true,
			});
		});
	};

	const handleLogout = () => {
		removeAllTokens();
		window.location.href = "https://intechadditive.us.auth0.com/v2/logout";
	};

	const DropdownItem = ({ Icon, text, onClick }) => {
		return (
			<div
				className={`${"dropdownItem"} ${"listItem"}`}
				onClick={onClick}
			>
				<span style={{ paddingLeft: "0.8rem" }}>{<Icon />}</span>
				{text}
			</div>
		);
	};

	//Remove active class if event is outside the component
	const toggleProfileDropdown = useCallback(
		(event) => {
			// Check whether the click target is within the component or not
			if (
				componentRef.current &&
				!componentRef.current.contains(event.target) &&
				activeClass
			) {
				setActiveClass(null);
			}
		},
		[componentRef, activeClass, toggleActiveClass]
	);
	//Add event listner to remove active class
	useEffect(() => {
		// Add event listener to window object when the component mounts
		window.addEventListener("click", toggleProfileDropdown);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("click", toggleProfileDropdown);
		};
	}, [toggleProfileDropdown]);

	return (
		<div
			className="profile-container"
			ref={componentRef}
		>
			<h3
				className={"userProfileContainer"}
				onClick={() => {
					toggleActiveClass("profile");
				}}
			>
				{userUnq ? userUnq?.firstName[0].toUpperCase() : <Skeleton />}
			</h3>

			{activeClass === "profile" && (
				<div
					className={`${"profile-dropdown"} ${
						activeClass === "profile" ? "open" : ""
					}`}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
							padding: "10px",
							textAlign: "center",
							fontWeight: "600",
							cursor: "default",
						}}
					>
						<h3 className={"userProfileContainer"}>
							{userUnq?.firstName[0].toUpperCase()}
						</h3>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "left",
								alignItems: "flex-start",
							}}
						>
							{userUnq?.firstName}
							<p style={{ fontSize: "12px", opacity: "0.4" }}>
								{userUnq?.email}
							</p>
						</div>
					</div>
					<hr
						color="lightgray"
						style={{ margin: 0 }}
					/>

					<DropdownItem
						text="Logout"
						Icon={() => <MdExitToApp size={20} />}
						onClick={handleLogout}
					/>
				</div>
			)}
		</div>
	);
};
