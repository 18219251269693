import { useState } from "react";

import { useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";

import { FaHome } from "react-icons/fa";
import { MdMenu } from "react-icons/md";
import logo from "./asset/newImages/logo.png";
import flag_logo from "./asset/img/logo-white.png";
import txt_logo from "./asset/img/logo-white-txt.png";
import ame from "./asset/newImages/AME.png";
import amp from "./asset/newImages/AMP.png";
import amv from "./asset/newImages/AMV.png";

import "./Dashboard.css";
import { NavLink } from "react-router-dom";
import { ProfileDropdown } from "./components/ProfileDropdown";
import Skeleton from "react-loading-skeleton";

const Dashboard = () => {
	const userUnq = useSelector((state) => state.authSlice?.user);
	console.log(userUnq);
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const handleToggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
	};

	const handleCardClick = (url) => {
		window.location.href = url;
	};

	return (
		<>
			<div className="dashboard-navbar">
				<div className="dashboard-brand">
					{/* {sidebarOpen && (
						<>
							<img
								src={flag_logo}
								width="50"
								alt="Company logo"
								className="dashboard-logo"
							/>
							<img
								src={txt_logo}
								width="100"
								alt="Company logo"
								className="dashboard-logo"
							/>
						</>
					)}
					{!sidebarOpen && (
						<img
							src={flag_logo}
							width="49"
							alt="Company logo"
							className="dashboard-logo"
						/>
					)} */}
					<div className="nav-logo">
						<div className="close_nav fade-in-image">
							<img
								src={flag_logo}
								alt="logo"
							/>
						</div>
						<div
							className={sidebarOpen ? "open_nav fade-in-image" : "close_nav"}
						>
							{sidebarOpen ? (
								<img
									src={txt_logo}
									alt="logo"
								/>
							) : (
								<div></div>
							)}
						</div>
					</div>
				</div>

				<button
					onClick={handleToggleSidebar}
					className="dashboard-toggle-button"
				>
					<MdMenu size={30} />
				</button>
				<ProfileDropdown />
			</div>

			<main className="dashboard-main-container">
				<div className={`dashboard-sidebar ${sidebarOpen ? "open" : ""}`}>
					<div className="dashboard-sidebar-content">
						<NavLink
							href="/"
							className="dashboard-nav-link side-active"
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "left",
									gap: "1rem",
								}}
							>
								<div className="sidebar-text">
									<FaHome
										className="sidebar-icon "
										size={25}
									/>
								</div>
								{sidebarOpen && <span className="sidebar-headings">Home</span>}
							</div>
						</NavLink>
					</div>
				</div>

				<div
					className={`dashboard-content ${sidebarOpen ? "sidebar-open" : ""}`}
				>
					<div>
						<h1
							style={{
								marginTop: "1rem",
								fontSize: "3.5rem",
								fontWeight: "bold",
								color: "#444f56",
								paddingTop: "2rem",
								display: "inline",
							}}
						>
							Hello2,{" "}
							{userUnq ? (
								userUnq?.firstName + "!"
							) : (
								<span>
									<Skeleton width={"15rem"} />
								</span>
							)}
						</h1>
						<p style={{ fontSize: "16px", color: "grey" }}>
							Get into the world of Additive Manufacturing with various products
							offered by Intech Additive Solution.
						</p>
					</div>

					<div className="dashboard-cards-container">
						<div
							className="card shadow bootstrap-card"
							onClick={() =>
								handleCardClick(process.env.REACT_APP_AMCOSTING_URL)
							}
							id="am-costing"
						>
							<img
								alt="top"
								src={logo}
								style={{ width: "16rem" }}
								className="card-img "
							/>
							<div>
								<div className="card-title">Description</div>
								<div className="card-text">
									Get an optimized cost estimation of 3D-printing.
								</div>
							</div>
						</div>
						<div
							className="card shadow bootstrap-card"
							onClick={() =>
								handleCardClick(process.env.REACT_APP_AMVARSITY_URL)
							}
							id="am-varsity"
						>
							<img
								alt="top"
								src={amv}
								style={{ width: "16rem" }}
								className="card-img"
							/>
							<div>
								<div className="card-title">Description</div>
								<div className="card-text">
									Knowledge hub for additive manufacturing.
								</div>
							</div>
						</div>
						<div
							className="card shadow bootstrap-card"
							onClick={() =>
								handleCardClick(process.env.REACT_APP_AMPLANNING_URL)
							}
							id="am-planing"
						>
							<img
								alt="top"
								src={amp}
								style={{ width: "16rem" }}
								className="card-img"
							/>
							<div>
								<div className="card-title">Description</div>
								<div className="card-text ">
									Optimise spare capacity available in machines.
								</div>
							</div>
						</div>
						<div
							className="card shadow bootstrap-card"
							onClick={() =>
								handleCardClick(process.env.REACT_APP_AMEXPLORER_URL)
							}
							id="am-explorer"
						>
							<img
								alt="top"
								src={ame}
								style={{ width: "16rem" }}
								className="card-img"
							/>
							<div>
								<div className="card-title">Description</div>
								<div className="card-text">
									Identify parts suitable for AM. Modify parts with generative
									design engine and simulate changes.
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default Dashboard;
