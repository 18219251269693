import axios from "axios";
import Cookies from "universal-cookie";

const isDevelopment = process.env.NODE_ENV;

// const BASE_URL = "http://localhost:8090"; 
// Set BASE_URL accordingly
const BASE_URL = isDevelopment === 'development'
	? "http://localhost:8006"
	: "https://dashboard.intechadditivesoftware.com";


const cookies = new Cookies();

export default axios.create({
	baseURL: "",
	headers: {
		'Authorization': `Bearer ${cookies.get("id_token")}`,
	},
});
