import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "./api/axios";
import Cookies from "universal-cookie";
import { redirect } from "react-router-dom";

import { clearUser, setUser } from "./Store/Slice/authSlice";
import "./App.css";



function App() {
	const { isAuthenticated } = useSelector((state) => state.authSlice);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchUser = async () => {
			// try {
				const cookies = new Cookies();
				const id_token=cookies.get("id_token");
				if(id_token){
					const userData = await axios.get(`https://api.intechadditivesoftware.com/verifyUser`)
				.then(response=>{
					console.log(`Is verified user `, response);
				    dispatch(setUser({ user: response.data.response }));
				    
				}).catch(err=>{
					if (err.response && err.response.status === 401) {
						console.log("Unauthorized access. Please log in.");
						console.log(err);
						dispatch(clearUser());
						window.location.href ="https://auth.intechadditivesoftware.com/"
					} else {
						// Handle other errors
						dispatch(clearUser());
						console.log("Error:", err);
						window.location.href ="https://auth.intechadditivesoftware.com/"
					}
				})
				}else{
					console.log("Unauthorized access. Please log in.");
					dispatch(clearUser());
					window.location.href ="https://auth.intechadditivesoftware.com/"

				}
				
			 // api call using axios to get
				
			// } catch (error) {
			// 	if (error.response && error.response.status === 401) {
			// 		console.log("Unauthorized access. Please log in.");
			// 		console.log(error);
			// 		dispatch(clearUser());
			// 		<redirect to="http://auth.intechadditivesoftware.com/" />;
			// 	} else {
			// 		// Handle other errors
			// 		dispatch(clearUser());
			// 		console.log("Error:", error);
			// 	}
			// }
		};
		fetchUser();
	}, []);

	return (
		<div className="App">
			<Routes>
				<Route
					path="/"
					element={<Dashboard />}
				/>
			</Routes>
		</div>
	);
}

export default App;
